
import { Options, Vue } from 'vue-class-component';
import Modal from '@/components/common/Modal.vue';
import api from '@/shared/api';

type Env = {
    name: string;
    key: string;
    redirect_uri: string;
};

@Options({
    components: {
        Modal,
    },
    props: {},
})
export default class ExternalSystemsAddSprinklr extends Vue {
    protected ready = false;
    protected environments: Env[] = [];

    async created() {
        if (this.$route.params.newOrId === 'callback') {
            console.log('code');
            const state = this.$route.query.state;
            if (state) {
                const stateParts = state.toString().split('-');
                const env = stateParts[0];
                const id = stateParts.slice(2).join('-');

                if (env === (window.location.port !== '443' ? 'development' : 'prod')) {
                    if (id === 'new') {
                        await api.addExternalSystem('sprinklr', '', this.$route.query);
                    } else {
                        await api.updateExternalSystem(id, 'sprinklr', '', this.$route.query);
                    }

                    this.$router.push({ name: 'external-systems' });
                }
            }
        }
        this.environments = await api.getExternalSystemSprinklrEnvironments();
        this.ready = true;
    }

    openSprinklr(env: Env) {
        const appStoreEnv = window.location.port !== '443' && window.location.port !== '' ? 'development' : 'prod';
        const state = appStoreEnv + '-extsystem-' + this.$route.params.newOrId;
        const redirect_uri = env.redirect_uri;
        const client_id = env.key;
        window.location.href =
            'https://api2.sprinklr.com/' + env.name + '/oauth/authorize?client_id=' + client_id + '&response_type=code&redirect_uri=' + redirect_uri + '&state=' + state;
    }
}
